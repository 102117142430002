<h1 mat-dialog-title>{{ 'Version ' + packageJson.version }} - Release Notes</h1>
<div mat-dialog-content>
  <h2>Neue Funktionen und Module</h2>
  <ul>
    <li>
      <strong>Neues Modul: "Gefahrstoffe"</strong>
      <p>Das neue Modul "Gefahrstoffe" ist ab sofort verfügbar und erweitert die Funktionalität der Software erheblich.</p>
      <p class="highlight">Hinweis: Bitte wenden Sie sich an unseren Support, um das Modul freizuschalten.</p>
    </li>
    <li>
      <strong>Überarbeitung des Moduls "Unterweisung"</strong>
      <ul>
        <li><strong>Aufteilung des Unterweisungsstands:</strong></li>
        <ul>
          <li><strong>Mitarbeiter:</strong> Zeigt den Fortschritt einzelner Mitarbeiter an.</li>
          <li><strong>Themen:</strong> Neue Ansichten ermöglichen die Nachverfolgung des Fortschritts zu einzelnen Themen.</li>
        </ul>
        <li><strong>Überarbeitete Zuweisung von Unterweisungen:</strong> Optimierungen sorgen für eine einfachere und effizientere Zuweisung.</li>
      </ul>
    </li>
  </ul>

  <h2>Optimierungen und Verbesserungen</h2>
  <ul>
    <li>
      <strong>Performance-Optimierungen:</strong>
      <p>Verbesserte Ladezeiten und schnellere Verarbeitung in der gesamten Anwendung.</p>
    </li>
    <li>
      <strong>Weitere Optimierungen:</strong>
      <p>Kleinere Verbesserungen und Fehlerbehebungen zur Steigerung der Benutzerfreundlichkeit.</p>
    </li>
  </ul>
</div>
<div mat-dialog-actions align="end">
  <button class="administration deleteButtonHover" color="primary" mat-raised-button type="button" (click)="close()"
          fxFlex.xs="0 0 calc(50% - 4px)">{{ 'close' | translate }}
  </button>
</div>

