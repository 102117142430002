import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export class ConfirmDialogConfig {
  title: string;
  showCancel?: boolean;
  message: string;
  secondMessage?: string;
  cancelText?: string;
  confirmText?: string;
  objectTitle?: string;

  constructor(config?: ConfirmDialogConfig) {
    if (config) {
      Object.keys(config)
        .filter(key => typeof config[key] !== 'undefined')
        .forEach(key => this[key] = config[key]);
    }
  }
}

@Component({
  selector: 'saf-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  public title: string;
  public message: string;
  public secondMessage: string;
  public cancelText: string;
  public objectTitle: string;
  public confirmText: string;
  public showCancel: boolean;

  constructor(
    public dialog: MatDialogRef<ConfirmDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) private config: ConfirmDialogConfig,
  ) {
    this.title = this.config.title;
    this.message = this.config.message;
    this.secondMessage = this.config.secondMessage;
    this.cancelText = this.config.cancelText;
    this.confirmText = this.config.confirmText;
    this.objectTitle = this.config.objectTitle;
    this.showCancel = this.config.showCancel;
  }
}
