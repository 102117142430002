import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import packageJson from '../../../../../package.json';


@Component({
  selector: 'saf-version-info-dialog',
  templateUrl: './version-info-dialog.component.html',
  styleUrls: ['./version-info-dialog.component.scss']
})
export class VersionInfoDialogComponent implements OnInit {
  protected readonly packageJson = packageJson;


  constructor(
    private dialogRef: MatDialogRef<VersionInfoDialogComponent>,

  ) { }

  ngOnInit(): void {
  }

  close(result: boolean = false) {
    this.dialogRef.close(result);
  }

}
