import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {tap} from "rxjs/operators";
import {ExternalCompany} from "@shared/models/external-company.model";
import {ExternalCompanyService} from "@shared/services/external-company.service";
import {
  ExternalCompanySelectDialogComponent
} from "@shared/components/external-company-select-dialog/external-company-select-dialog.component";
import {checkFilter} from '@shared/utils/utils';

@Component({
  selector: 'saf-external-company-select',
  templateUrl: './external-company-select.component.html',
  styleUrls: ['./external-company-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ExternalCompanySelectComponent),
      multi: true
    }
  ],
})
export class ExternalCompanySelectComponent implements OnInit, ControlValueAccessor {
  _valueText: string = '';
  private externalCompany: ExternalCompany;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) { this._required = coerceBooleanProperty(value); }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled || this._loading; }
  set disabled(value: boolean) { this._disabled = coerceBooleanProperty(value); }
  public _disabled = false;

  @Input()
  get multiple(): boolean { return this._multiple; }
  set multiple(value: boolean) { this._multiple = coerceBooleanProperty(value); }
  private _multiple = false;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) { this._placeholder = value; }
  private _placeholder: string;

  @Input()
  get appearance(): MatFormFieldAppearance { return this._appearance; }
  set appearance(value: MatFormFieldAppearance) { this._appearance = value; }
  private _appearance: MatFormFieldAppearance = 'outline';

  @Input()
  get value(): any { return this._value; }
  set value(value: any) { this.writeValue(value); }
  private _value: any = null;

  @Input()
  get isFilter(): boolean { return this._isFilter; }
  set isFilter(value: boolean) { this._isFilter = coerceBooleanProperty(value); }
  private _isFilter = false;

  get loading(): boolean { return this._loading; }
  private _loading: boolean = false;

  get hasValue(): boolean {
    if (this.disabled === false) {
      return !!this._valueText;
    } else {
      return false;
    }
  }

  get hasNoValue(): boolean {
    if (this._valueText === '' || this.disabled === true) {
      return true;
    }
  }

  externalCompanies: ExternalCompany[] = [];

  @Output() valueChange = new EventEmitter<any>();
  @Input() listName: string;

  onChange: Function = () => {};
  onTouched: Function = () => {};

  constructor(
    private externalCompanyService: ExternalCompanyService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.checkSavedFilter();
  }

  checkSavedFilter() {
    if (checkFilter('externalCompanyId', this.listName)) {
      this.writeValue(checkFilter('externalCompanyId', this.listName));
    }
  }

  ngAfterViewInit() {
    this.loadById(this.value);
  }

  registerOnChange(onChange: Function) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: Function) {
    this.onTouched = onTouched;
  }

  writeValue(value: any) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
    this.valueChange.emit(value);
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  loadById(id?: number) {
    if (id) {
      this._loading = true;

      this.externalCompanyService.getById(id)
        .pipe(tap(() => this._loading = false))
        .subscribe((response) => {
          this._valueText = response.name;
          this.externalCompany = response;
        });
    }
  }

  openExternalCompanySelectDialog(e) {
    if (this.disabled) { return; }

    const dialogConfig: MatDialogConfig = {
      panelClass: 'table-dialog',
      disableClose: true,
      data: {
        multiple: this.multiple,
        selected: this.externalCompany ? this.externalCompany instanceof Array ? this.externalCompany : [this.externalCompany] : null,
      }
    };

    this._loading = true;
    this.dialog.open(ExternalCompanySelectDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(tap(() => this._loading = false))
      .subscribe((result) => {
        if (!result) {
          return;
        }
        if (result.length === 0) {
          this.value = null;
          this._valueText = '';
          this.externalCompany = null;
        } else {
          this.value = this.multiple
            ? result.map(externalCompany => externalCompany.id)
            : result.map(externalCompany => externalCompany.id)[0];
          this._valueText = this.multiple
            ? result.map((externalCompany) => externalCompany.name).join(', ')
            : result.map(externalCompany => externalCompany.name)[0];
          this.externalCompany = result;
        }
      });
  }

  clear() {
    this.value = null;
    this._valueText = '';
    this.externalCompany = null;
  }

  onClickClear(event) {
    this.clear();
    event.stopImmediatePropagation();
  }
}
