import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import packageJson from '../../../../../package.json';
import {VersionInfoDialogComponent} from '@shared/components/version-info-dialog/version-info-dialog.component';

@Component({
  selector: 'saf-infobox-dialog',
  templateUrl: './infobox-dialog.component.html',
  styleUrls: ['./infobox-dialog.component.scss']
})
export class InfoboxDialogComponent implements OnInit {
  protected readonly packageJson = packageJson;

  constructor(
    private dialogRef: MatDialogRef<InfoboxDialogComponent>,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  close(result: boolean = false) {
    this.dialogRef.close(result);
  }

  mailTo() {
    window.location.href = 'mailto:support@safety-doors.com';
  }

  openSupportPortal() {
    window.open('https://desire-solution.freshdesk.com');
  }

  openVersionInfoDialog() {
    const dialogConfig: MatDialogConfig = {
      panelClass: 'small-dialog',
      disableClose: false
    };
    this.dialog.open(VersionInfoDialogComponent, dialogConfig);
  }
}
