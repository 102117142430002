import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ModuleName} from '@shared/models/module-name.model';
import {Select, Store} from '@ngxs/store';
import {AuthState} from '@shared/store/auth/auth.state';
import {ModuleColor} from '@shared/models/module-color';
import {isMobileViewport} from '@shared/utils/utils';
import {AppConfigService} from '@shared/services/app-config.service';

interface NavigationRoute {
  path: string;
  label: string;
  icon?: string;
  module?: ModuleName;
  navigation: string;
  color?: string;
  permission?: string;
  add?: boolean;
}

interface NavigationGroup {
  label: string;
  icon: string;
  routes?: NavigationRoute[];
  children?: NavigationGroup[];
  color?: string;
  iconLabel?: string;
}

@Component({
  selector: 'saf-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  mobileViewport = isMobileViewport();
  @Select(AuthState.userDetails) userDetails$;
  public categories: NavigationGroup[] = [
    {
      label: 'navigation',
      icon: 'dashboard',
      color: ModuleColor.NAVIGATION,
      routes: [
        {path: '/dashboard', label: 'dashboard', navigation: 'navigation'},
      ]
    },
    {
      label: 'safety-Ehs',
      icon: 'room_preferences',
      color: ModuleColor.USER_ADMINISTRATION,
      routes: [
        {path: '/department', label: 'corporateStructure', module: ModuleName.USER_ADMINISTRATION, icon: 'domain', navigation: 'safety-Ehs'},
        {path: '/employee', label: 'employees', module: ModuleName.USER_ADMINISTRATION, icon: 'group', navigation: 'safety-Ehs'},
        {path: '/employee-category', label: 'employeeCategories', module: ModuleName.USER_ADMINISTRATION, icon: 'category', navigation: 'safety-Ehs'},
        {path: '/permission-profile', label: 'permissionProfile', module: ModuleName.USER_ADMINISTRATION, icon: 'security', navigation: 'safety-Ehs'},
        {path: '/cost-center', label: 'costCenters', module: ModuleName.USER_ADMINISTRATION, icon: 'payments', navigation: 'safety-Ehs'},
        {path: '/mailingSettings', label: 'notificationSystem', module: ModuleName.USER_ADMINISTRATION, icon: 'mail', navigation: 'safety-Ehs'},
        {path: '/checklist', label: 'checklists', module: ModuleName.USER_ADMINISTRATION, icon: 'assignment_turned_in', navigation: 'safety-Ehs'},
        {path: '/license', label: 'licenses', module: ModuleName.USER_ADMINISTRATION, icon: 'receipt_long', navigation: 'safety-Ehs'},
        {path: '/file-system', label: 'fileStorage', module: ModuleName.USER_ADMINISTRATION, icon: 'folder_open', navigation: 'safety-Ehs' },
        {path: '/area-location', label: 'locations', module: ModuleName.USER_ADMINISTRATION, icon: 'location_on', navigation: 'safety-Ehs' },
        {path: '/analyse', label: 'analyses', module: ModuleName.USER_ADMINISTRATION, icon: 'pie_chart', navigation: 'safety-Ehs'},
        {path: '/symbol-icon', label: 'symbol-icon', module: ModuleName.USER_ADMINISTRATION, icon: 'photo_library', navigation: 'safety-Ehs'},
        {path: '/roles', label: 'roles', module: ModuleName.USER_ADMINISTRATION, icon: 'manage_accounts', navigation: 'safety-Ehs'},
      ]
    },
    {
      label: 'observations',
      icon: 'visibility',
      color: ModuleColor.OBSERVATION,
      routes: [
        // {path: '/observation/create', label: 'newObservation', module: ModuleName.OBSERVATION, icon: 'visibility', navigation: 'observations', permission: PermissionKeys.OBSERVATION_EDIT, add: true},
        {path: '/observation', label: 'observation-overview', module: ModuleName.OBSERVATION, icon: 'visibility', navigation: 'observations'},
        {path: '/observation-type', label: 'observationTypes', module: ModuleName.OBSERVATION, icon: 'pie_chart', navigation: 'observations'},
      ]
    },
    {
      label: 'auditsAndInspections',
      icon: 'find_in_page',
      color: ModuleColor.AUDIT,
      routes: [
        // {path: '/audit/create', label: 'newAudit', module: ModuleName.AUDIT, icon: 'find_in_page', navigation: 'auditsAndInspections', permission: PermissionKeys.AUDIT_EDIT, add: true },
        {path: this.mobileViewport ? 'audit/mobile' : '/audit', label: 'audit-overview', module: ModuleName.AUDIT, icon: 'find_in_page', navigation: 'auditsAndInspections' },
        // {path: '/inspection/create', label: 'newInspection', module: ModuleName.AUDIT, icon: 'search', navigation: 'auditsAndInspections', permission: PermissionKeys.AUDIT_INSPECTION_EDIT, add: true},
        {path: this.mobileViewport ? 'inspection/mobile' : '/inspection', label: 'inspection-overview', module: ModuleName.AUDIT, icon: 'search', navigation: 'auditsAndInspections'},
        {path: '/inspection-checklist', label: 'checklists', module: ModuleName.AUDIT, icon: 'assignment_turned_in', navigation: 'auditsAndInspections'},

      ]
    },
    {
      label: 'hazardAssessments',
      iconLabel: 'hazardAssessmentsBreak',
      icon: 'warning',
      color: ModuleColor.HAZARD_ASSESSMENT,
      routes: [
        // {path: '/hazard-assessment/create', label: 'addNewHazardAssessmentBreak', module: ModuleName.HAZARD_ASSESSMENT, icon: 'warning', navigation: 'hazardAssessments', permission: PermissionKeys.HAZARD_ASSESSMENT_ADD, add: true},
        {path: '/hazard-assessment', label: 'hazard-overviewBreak', module: ModuleName.HAZARD_ASSESSMENT, icon: 'warning', navigation: 'hazardAssessments'},
        {path: '/hazard-assessment-template-category', label: 'templateCategories', module: ModuleName.HAZARD_ASSESSMENT, icon: 'category', navigation: 'hazardAssessments'},
        {path: '/hazard-assessment-template', label: 'templates', module: ModuleName.HAZARD_ASSESSMENT, icon: 'list', navigation: 'hazardAssessments'},
        {path: '/hazard-group', label: 'groupsAndFactors', module: ModuleName.HAZARD_ASSESSMENT, icon: 'group_work', navigation: 'hazardAssessments'},
        // {path: '/hazard-checklist', label: 'checklists', module: ModuleName.HAZARD_ASSESSMENT, icon: 'assignment_turned_in', navigation: 'hazardAssessments'},
        {path: '/hazard-law', label: 'lexica', module: ModuleName.HAZARD_ASSESSMENT, icon: 'gavel', navigation: 'hazardAssessments' },
        {path: '/hazard-rating-type', label: 'riskAssessments', module: ModuleName.HAZARD_ASSESSMENT, icon: 'trending_up', navigation: 'hazardAssessments' },
      ],
    },
    {
      label: 'instructions',
      iconLabel: 'companyInstructions',
      icon: 'assignment',
      color: ModuleColor.INSTRUCTION,
      routes: [
        // {path: '/instruction/create', label: 'addNewInstruction', module: ModuleName.INSTRUCTION, icon: 'assignment', navigation: 'instructions', permission: PermissionKeys.INSTRUCTION_ADD, add: true},
        {path: '/instruction', label: 'instruction-overview', module: ModuleName.INSTRUCTION, icon: 'assignment', navigation: 'instructions'},
        {path: '/instruction-template', label: 'textblocks', module: ModuleName.INSTRUCTION, icon: 'extension', navigation: 'instructions'},
        {path: '/instruction-pattern', label: 'templates', module: ModuleName.INSTRUCTION, icon: 'list', navigation: 'instructions'},
        {path: '/instruction-type', label: 'instructionTypesBreak', module: ModuleName.INSTRUCTION, icon: 'category', navigation: 'instructions'},
      ]
    },
    {
      label: 'schoolings',
      icon: 'school',
      color: ModuleColor.SCHOOLING,
      routes: [
        // { path: '/schooling-plan/multiple', label: 'schoolingPlanAdd', module: ModuleName.SCHOOLING, icon: 'calendar_month', navigation: 'schoolings', permission: PermissionKeys.SCHOOLING_PLAN_EDIT, add: true },
        { path: '/schooling-plan', label: 'schoolingPlanOverviewEmployee', module: ModuleName.SCHOOLING, icon: 'group', navigation: 'schoolings'},
        // { path: '/schooling/create', label: 'personalSchoolingAdd', module: ModuleName.SCHOOLING, icon: 'school', navigation: 'schoolings', permission: PermissionKeys.SCHOOLING_TUITION_EDIT, add: true},
        { path: '/schooling-plan-topic', label: 'schoolingPlanTopicOverviewStatus', module: ModuleName.SCHOOLING, icon: 'library_books', navigation: 'schoolings'},
        { path: '/schooling', label: 'personalSchoolings', module: ModuleName.SCHOOLING, icon: 'school', navigation: 'schoolings'},
        { path: '/schooling-topic', label: 'schoolingTopicOverview', module: ModuleName.SCHOOLING, icon: 'list_alt', navigation: 'schoolings'},
        // { path: '/schooling-topic-category', label: 'topicCategories', module: ModuleName.SCHOOLING, icon: 'category', navigation: 'schoolings'},
        { path: '/schooling-package', label: 'schoolingPackageOverview', module: ModuleName.SCHOOLING, icon: 'dataset', navigation: 'schoolings'}
      ]
    },
    {
      label: 'qualifications',
      icon: 'verified',
      color: ModuleColor.QUALIFICATION,
      routes: [
        // { path: '/qualification/create', label: 'createQualification', module: ModuleName.QUALIFICATION, icon: 'verified', navigation: 'qualifications', permission: PermissionKeys.QUALIFICATION_EDIT, add: true },
        { path: '/qualification', label: 'qualification-overview', module: ModuleName.QUALIFICATION, icon: 'verified', navigation: 'qualifications' },
        // { path: '/qualification-employee/create', label: 'assignQualification', module: ModuleName.QUALIFICATION, icon: 'contact_page', navigation: 'qualifications', permission: PermissionKeys.QUALIFICATION_EMPLOYEE_EDIT, add: true },
        { path: '/qualification-employee', label: 'qualificationsAssigned', module: ModuleName.QUALIFICATION, icon: 'contact_page', navigation: 'qualifications' },
        { path: '/qualification-type', label: 'qualificationTypesBreak', module: ModuleName.QUALIFICATION, icon: 'category', navigation: 'qualifications' },
        { path: '/credential', label: 'credential', module: ModuleName.QUALIFICATION, icon: 'receipt_long', navigation: 'qualifications' },
      ]
    },
    {
      label: 'accidentBook',
      icon: 'local_hospital',
      color: ModuleColor.MEDICAL,
      routes: [
        {path: '/accident-preReport', label: 'accident-preReport-overview', module: ModuleName.MEDICAL, icon: 'personal_injury', navigation: 'accidentBook'},
        // {path: '/accident-book/create', label: 'addNewAccident', module: ModuleName.MEDICAL, icon: 'local_hospital', navigation: 'accidentBook', permission: PermissionKeys.ACCIDENT_COMPLETE_EDIT, add: true},
        {path: '/accident-book', label: 'accident-book-overview', module: ModuleName.MEDICAL, icon: 'local_hospital', navigation: 'accidentBook'},
      ]
    },
    {
      label: 'screening',
      icon: 'multiline_chart',
      color: ModuleColor.SCREENING,
      routes: [
        // { path: '/screening-examination/multiple', label: 'createExamination', module: ModuleName.SCREENING, icon: 'ecg', navigation: 'screening', permission: PermissionKeys.SCREENING_ADD, add: true },
        { path: '/screening-examination', label: 'examination-overview', module: ModuleName.SCREENING, icon: 'ecg', navigation: 'screening' },
        // { path: '/screening-examination-screening', label: 'examination-overview-screening', module: ModuleName.SCREENING, icon: 'ecg', navigation: 'screening' },
        // { path: '/screening/create', label: 'createScreening', module: ModuleName.SCREENING, icon: 'multiline_chart', navigation: 'screening', permission: PermissionKeys.SCREENING_ADD, add: true },
        { path: '/screening', label: 'screening-overviewBreak', module: ModuleName.SCREENING, icon: 'multiline_chart', navigation: 'screening' },
        { path: '/screening-package', label: 'screening-package-overview', module: ModuleName.SCREENING, icon: 'dataset', navigation: 'screening' },
      ]
    },
    {
      label: 'maintenancePlanner',
      icon: 'construction',
      color: ModuleColor.MAINTENANCEPLANNER,
      routes: [
        // { path: '/maintenance-equipment/create', label: 'createEquipment', module: ModuleName.INSPECTION, icon: 'construction', navigation: 'maintenancePlanner', permission: PermissionKeys.MAINTENANCE_EDIT, add: true },
        { path: '/maintenance-equipment', label: 'equipmentOverview', module: ModuleName.INSPECTION, icon: 'construction', navigation: 'maintenancePlanner' },
        // { path: '/maintenance-exam/create', label: 'createExam', module: ModuleName.INSPECTION, icon: 'content_paste', navigation: 'maintenancePlanner', permission: PermissionKeys.MAINTENANCE_EDIT, add: true },
        { path: '/maintenance-exam', label: 'examOverview', module: ModuleName.INSPECTION, icon: 'content_paste', navigation: 'maintenancePlanner' },
        { path: '/maintenance-exam-execution', label: 'examExecutionOverviewBreak', module: ModuleName.INSPECTION, icon: 'pending_actions', navigation: 'maintenancePlanner' },
        { path: '/maintenance-equipment-category', label: 'equipmentCategories', module: ModuleName.INSPECTION, icon: 'category', navigation: 'maintenancePlanner' },
        { path: '/maintenance-exam-type', label: 'examTypes', module: ModuleName.INSPECTION, icon: 'pie_chart', navigation: 'maintenancePlanner' },
        { path: '/maintenance-checklist', label: 'checklists', module: ModuleName.INSPECTION, icon: 'assignment_turned_in', navigation: 'maintenancePlanner' },
      ]
    },
    {
      label: 'externalCompanies',
      icon: 'business_center',
      color: ModuleColor.EXTERNAL_COMPANIES,
      routes: [
        // {path: '/external-company/create', label: 'addNewExternalCompany', module: ModuleName.EXTERNAL_COMPANIES, icon: 'business_center', navigation: 'externalCompanies', permission: PermissionKeys.EXTERNAL_COMPANY_EDIT, add: true},
        {path: '/external-company', label: 'external-company-overview', module: ModuleName.EXTERNAL_COMPANIES, icon: 'business_center', navigation: 'externalCompanies'},
        // {path: '/external-company-employee/create', label: 'newExternalCompanyEmployee', module: ModuleName.EXTERNAL_COMPANIES, icon: 'group', navigation: 'externalCompanies', permission: PermissionKeys.EXTERNAL_EMPLOYEE_EDIT, add: true},
        {path: '/external-company-employee', label: 'overViewExternalCompanyEmployeesBreak', module: ModuleName.EXTERNAL_COMPANIES, icon: 'group', navigation: 'externalCompanies'},
        {path: '/external-company-category', label: 'externalCompanyCategories', module: ModuleName.EXTERNAL_COMPANIES, icon: 'category', navigation: 'externalCompanies'},
        {path: '/terminals', label: 'terminals', module: ModuleName.EXTERNAL_COMPANIES, icon: 'devices', navigation: 'externalCompanies'},
      ]
    },
    {
      label: 'measures',
      icon: 'feedback',
      color: ModuleColor.MEASURE,
      routes: [
        // {path: '/measure/create', label: 'addNewMeasure', module: ModuleName.Measure, icon: 'feedback' , navigation: 'measures', permission: PermissionKeys.MEASURE_EDIT, add: true},
        {path: '/measure', label: 'measure-overview', module: ModuleName.Measure, icon: 'feedback', navigation: 'measures'},
        {path: '/measure-type', label: 'measureTypes', module: ModuleName.Measure, icon: 'category', navigation: 'measures'},
      ]
    },
    {
      label: 'hazardousSubstanceManagement',
      icon: 'science',
      color: ModuleColor.HAZARDOUS_SUBSTANCE,
      routes: [
        {path: '/hazardousSubstance', label: 'hazardousSubstances', module: ModuleName.HAZARDOUS_SUBSTANCE, icon: 'science', navigation: 'hazardousSubstanceManagement'},
        {path: '/adr-hazardous', label: 'adrHazardousSubstanceTransport', module: ModuleName.HAZARDOUS_SUBSTANCE, icon: 'legend_toggle', navigation: 'hazardousSubstanceManagement'},
      ]
    },
    {
      label: 'controlling',
      icon: 'data_usage',
      color: ModuleColor.CONTROLLING,
      routes: [
        {path: '/controlling', label: 'controlling', module: ModuleName.BETA, icon: 'data_usage', navigation: 'controlling'},
      ]
    },
    // {
    //   label: 'chemicals',
    //   icon: 'science',
    //   color: ModuleColor.CHEMICALS,
    //   routes: [
    //     {path: '/dashboard', label: 'addNewMeasure', module: ModuleName.Measure, icon: 'feedback', navigation: 'chemicals'},
    //     {path: '/dashboard', label: 'chemicals-overview', module: ModuleName.Measure, icon: 'feedback', navigation: 'chemicals'},
    //   ]
    // },
  ];

  public filteredCategories = [];

  constructor(
    public router: Router,
    private store: Store,
    private route: ActivatedRoute,
    private appConfig: AppConfigService,
  ) {
  }

  ngOnInit() {
    this.userDetails$.subscribe(() => this.filterCategories());
  }

  getCategories() {
    this.filterCategories();
    return this.filteredCategories;
  }

  getFilteredCategories(label: string) {
    return this.categories.filter(navigation => navigation.label === label);
  }

  filterCategories() {
    this.filteredCategories = this.categories.filter((category) => !!this.filterRoutes(category.routes).length);
  }

  filterRoutes(routes) {
    if (this.appConfig.appConfig.showBetaModule) {
      return routes.filter((route) => !route.module || this.store.selectSnapshot(AuthState.assignedModules).includes(route.module) || route.module === 'beta');

    } else {
      return routes.filter((route) => !route.module || this.store.selectSnapshot(AuthState.assignedModules).includes(route.module));
    }
  }

  isCategoryActive(category) {

    if (this.router.url.includes('navigation')) {
      return category.routes.some((route) =>  this.route.snapshot.queryParamMap.get('navigation') === route.navigation);
    } else {
      return category.routes.some((route) => this.router.isActive(route.path, false));
    }
  }
}
