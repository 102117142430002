export enum ModuleName {
  USER_ADMINISTRATION = 'userAdministration',
  SCHOOLING = 'schooling',
  SCHOOLING_ANALYSIS = 'schoolingAnalysis',
  OTHER_ANALYSIS = 'otherAnalysis',
  REPORT_ANALYSIS = 'reportAnalysis',
  MEDICAL = 'medical',
  MEDICAL_ANALYSIS = 'medicalAnalysis',
  INSPECTION = 'inspection',
  EXTERNAL_COMPANIES = 'externalCompanies',
  Measure = 'measurement',
  HAZARDOUS_SUBSTANCE = 'hazardousSubstance',
  SCREENING = 'screening',
  HAZARD_ASSESSMENT = 'hazardAssessment',
  PERMISSION_SETTINGS = 'permissionSettings',
  LICENSE_SETTINGS = 'licenseSettings',
  QUALIFICATION = 'qualification',
  AUDIT = 'audit',
  MAIL_SETTINGS = 'mailSettings',
  SAFETY = 'safety',
  INSTRUCTION = 'instruction',
  OBSERVATION = 'observation',
  CONTROLLING = 'controlling',
  BETA = 'beta',
}
