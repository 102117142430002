import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthState } from '@shared/store/auth/auth.state';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class HasAssignedModuleGuard implements CanActivate {

  constructor(
    private store: Store,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {

    if (('module' in route.data) && !this.store.selectSnapshot(AuthState.assignedModules).includes(route.data.module)) {
      this.router.navigateByUrl('/');

      return false;
    }

    return true;
  }
}
