import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MaterialModule} from '@shared/material.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {LoadingOverlayModule} from '@shared/components/loading-overlay/loading-overlay.module';
import {FilterPanelModule} from '@shared/components/filter-panel/filter-panel.module';
import {VersionInfoDialogComponent} from '@shared/components/version-info-dialog/version-info-dialog.component';
import {FlexModule} from '@angular/flex-layout';



@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    PipesModule,
    LoadingOverlayModule,
    FilterPanelModule,
    FlexModule,
  ],
  declarations: [
    VersionInfoDialogComponent,
  ],
  exports: [
    VersionInfoDialogComponent,
  ]
})
export class VersionInfoDialogModule { }
